import domReady from '../plugins/domready';

function toggleMessage(element, visible) {
	if(visible) {
		element.classList.remove('hidden');
		element.setAttribute('aria-hidden', 'false');
	} else {
		element.classList.add('hidden');
		element.setAttribute('aria-hidden', 'true');
	}
};

if ("serviceWorker" in navigator) {
	if(window.pwaEnabled) {

		navigator.serviceWorker.register("/worker.js").then(registration => {
			if (registration.installing) {
				console.log("Service worker installing");
			} else if (registration.waiting) {
				console.log("Service worker installed");
			} else if (registration.active) {
				console.log("Service worker active");
			}
		}).catch(err => {
			console.error(`Service worker registration failed with ${err}`);
		});

		domReady(() => {
			const offlineMessage = document.querySelector('#offline-message');
			const header = document.getElementById('nav-header');

			header?.appendChild(offlineMessage);


			if(offlineMessage) {
				if(!navigator.onLine) {
					toggleMessage(offlineMessage, 1);
				}

				window.addEventListener('offline', () => {
					toggleMessage(offlineMessage, 1);
				});

				window.addEventListener('online', () => {
					toggleMessage(offlineMessage, 0);
				});
			}
		});

	} else {
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			for (const registration of registrations) {
				registration.unregister();
			}
		});
	}
}
